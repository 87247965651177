import React, {useState, useRef, useEffect} from "react";
import Btn from "../Btn/Btn";
import { useOutsideClick } from "../helpers/hooks";
import DropdownLayout from "../DropdownLayouts/DropdownLayout";
import ConnectWalletDropdown from "../DropdownLayouts/ConnectWalletDropdown";
import NetworkDropdown from "../DropdownLayouts/NetworkDropdown";
import globals_functions from "../../globals";

//-------- Dfinity modules -------
import {Actor, HttpAgent} from "@dfinity/agent";
import {Principal} from "@dfinity/principal";
import {StoicIdentity} from "ic-stoic-identity";

// import ComingSoonPopUp from "./ComingSoonPopUp";

function ConnectWallet({
  showPopup,
  setShowPopup,
  setUserAddress,
  header = false,
  big,
  userAddress,
}) {

  const globals = globals_functions();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownNetwork, setShowDropdownNetwork] = useState(false);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  // const logged
  const [logged, setLogged] = useState(false);
  const [wallet, setWallet] = useState(null);

  const connectPlugWallet = async () => {
    // detect if browser safary
    if ( isSafari ) {
      document.dispatchEvent(
          new CustomEvent('show_alert', { detail: {
              message: 'Sorry but Plug wallet plugin does not work in this browser. Please open Chrome or Firefox',
              title: 'Plugin not installed!',
              type: 'error'
            }})
      );
    } else {
      window.wallet_type = 'plug';
      createPlugActor();
    }
  };

  /** ==============================================================
   * ================== Create Plug Actor  ============================
   * ============================================================== */
  const createPlugActor = async () => {
    const whitelist = [ window.canisters['burn_battle'] ];
    //console.dir(whitelist);
    let principalId = '';
    await window?.ic?.plug?.requestConnect({
      whitelist,
    });
    principalId = await window.ic.plug.agent.getPrincipal();
    window.client_principal = principalId;

    window.principal_key = Principal.fromUint8Array( principalId._arr ).toText();
    //console.log("Plug principal: ", window.principal_key );
    getClientLedger(principalId);
  };

  /**
   * Buy and mint a new warrior
   */
  function change_network() {
    globals.modalAlert('Coming soon', 'We are working on developing this functionality', 'soon');
  }


  /** ==============================================================
   * ================ Wallet Connect Stoic  ========================
   * ============================================================== */
  const connectStoicWallet = async () => {

    setShowDropdown(false);

    document.dispatchEvent(  new CustomEvent('show_modal_loader',{
      detail: { text: "We are connecting your wallet", state: true }
    }));

    window.wallet_type = 'stoic';
    StoicIdentity.load().then(async identity => {
      if (identity !== false) {
        //wallet has been already connected
        //console.log("stoic loaded");
        window.stoic_identity = identity;
      } else {
        //No existing connection, lets make one!
        identity = await StoicIdentity.connect();
        window.stoic_identity = identity;
      }
      //console.dir( identity );
      let principal = await identity.getPrincipal();
      window.principal_key = Principal.fromUint8Array( principal._arr ).toText();
      //console.log("principal: ", window.principal_key );
      if( !window.client_ledger || window.client_ledger == '') {
        getClientLedger(principal);
      } else {
        handleLogin();
      }
    });
  };

  /*
* ================= Get Client's ledger =====================
*/
  const getClientLedger = async (client_principal) => {

    let principal = window.client_principal = Principal.fromUint8Array(client_principal._arr);
    let canisterId = window.canisters['burn_battle'];
    window.actors['burn_battle'] = Actor.createActor(window.idlFactory['burn_battle'], {
      agent: new HttpAgent({
        host: "https://" + canisterId + ".raw.ic0.app/",
      }),
      canisterId
    });

    window.actors['burn_battle'].ledgerFromPrincipal(principal).then(client_ledger => {
      //console.dir( client_ledger );
      window.client_ledger = client_ledger;
      setWallet( client_ledger );
      document.dispatchEvent(  new CustomEvent('client_ledger',{ detail: { client_ledger }}));
      handleLogin();
    });
  };


  /** ==============================================================
   * ================== send_Login ============================
   * ============================================================== */
  const handleLogin = () => {

    //document.dispatchEvent( new CustomEvent('preloader', { detail: { state: false } }));
    setShowDropdown(false);
    document.dispatchEvent( new CustomEvent('refresh_tokens', { detail: true }));
    window.logged_user = true;
    setLogged(true);
    document.dispatchEvent(  new CustomEvent('show_modal_loader',{
      detail: { text: "", state: false }
    }));

    // save to local storage: stoic identity, principal, client ledger
    let params = {
      client_ledger: window.client_ledger,
      wallet_type: window.wallet_type,
      principal_key: window.principal_key,
      client_principal: window.client_principal,
      stoic_identity: window.stoic_identity
    };
    localStorage.setItem('ic_params', JSON.stringify(params));

  };

  /** ==============================================================
   * =================== selectNetwork ICP  ========================
   * ============================================================== */
  const selectNetwork_ICP = async ( emit ) => {
    setShowDropdownNetwork(false);
  };

  const selectNetwork_SOL = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
    //console.log('selectNetwork_SOL');
  };

  const selectNetwork_ETH = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
    //console.log('selectNetwork_ETH');
  }

  const selectNetwork_APT = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
  }

  const selectNetwork_SUI = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
  }

  const selectNetwork_BTC = async ( emit ) => {
    change_network();
    setShowDropdownNetwork(false);
  }

  /** ==============================================================
   * ================== logout & disconnect  ==================== */
  function disconnect() {
    StoicIdentity.disconnect();
    window.logged_user = false;
    window.client_ledger = '';
    setLogged(false);
    setWallet( '');
    localStorage.removeItem('ic_params');
    window.location.reload();
  }


  // reconnect wallet
  setTimeout( () => {
    if( window.client_ledger && window.client_ledger != '' ) {
      window.logged_user = true;
      setLogged(true);
      setWallet( window.client_ledger );
    }
  }, 1000);

  return (
    <div className="flex items-center">
      {!userAddress ? (
        <div className="relative">
          <table>
            <tr>
              <td valign="top">
                {!logged ? (
                <div className="relative">
                  <Btn onClick={(e) => { e.stopPropagation();  setShowDropdownNetwork(!showDropdownNetwork); setShowDropdown(false); }}>
                    <div className="flex gap-2 items-center ">
                      <img className="" src="/assets/icp-gray.svg" />
                      <span className="relative top-0.5">ICP</span>
                      <img className="px-1" src="/assets/arrow-down.svg" style={{"width": "20px"}} />
                    </div>{" "}
                  </Btn>
                      {<NetworkDropdown
                    show={showDropdownNetwork}
                    setShowDropdown={setShowDropdownNetwork}
                    selectNetwork_ICP={selectNetwork_ICP}
                    selectNetwork_SOL={selectNetwork_SOL}
                    selectNetwork_ETH={selectNetwork_ETH}
                    selectNetwork_APT={selectNetwork_APT}
                    selectNetwork_SUI={selectNetwork_SUI}
                    selectNetwork_BTC={selectNetwork_BTC}
                    ></NetworkDropdown>}
                </div>
                ) : (
                   <div className='relative'>

                     <table>
                       <tr>
                         <td className={'pr-3'} valign="top">
                           <div className="relative">
                             <Btn color="dark" onClick={(e) => { e.stopPropagation();  setShowDropdownNetwork(!showDropdownNetwork); setShowDropdown(false); }}>
                               <div className="flex gap-2 items-center ">
                                 <span className="relative top-0.5">ICP</span>
                                 <img className="px-1" src="/assets/arrow-down.svg" style={{"width": "20px"}} />
                               </div>{" "}
                             </Btn>
                             {<NetworkDropdown
                                 show={showDropdownNetwork}
                                 setShowDropdown={setShowDropdownNetwork}
                                 selectNetwork_ICP={selectNetwork_ICP}
                                 selectNetwork_SOL={selectNetwork_SOL}
                                 selectNetwork_ETH={selectNetwork_ETH}
                                 selectNetwork_APT={selectNetwork_APT}
                                 selectNetwork_SUI={selectNetwork_SUI}
                                 selectNetwork_BTC={selectNetwork_BTC}
                             ></NetworkDropdown>}
                           </div>
                         </td>
                         <td valign="top">
                           <Btn id="logged_wallet_btn" color="grey" onClick={(e) => { e.stopPropagation(); disconnect(); }}>
                             <div className="flex gap-2 items-center " style={{"maxWidth" : "100px"}}>
                               <img className="" src="/assets/hero-screen/wallet.svg" alt="Connect Wallet" />
                               <span className="relative top-0.5 text-trim logged_wallet">
                                 <b className='wallet_address'>{wallet}</b>
                                 <b className="hover_logout">Log Out</b>
                               </span>
                             </div>{" "}
                           </Btn>
                         </td>
                       </tr>
                     </table>
                   </div>
               )}
              </td>

              <td valign="top" className="pl-3">
                {!logged ? (
                <Btn color="bronze" onClick={(e) => { e.stopPropagation();  setShowDropdown(!showDropdown); setShowDropdownNetwork(false); }}>
                  <div className="flex gap-2 items-center ">
                    <img className="" src="/assets/hero-screen/wallet.svg" alt="Connect Wallet" />
                    <span className="relative top-0.5">Connect</span>
                  </div>{" "}
                </Btn>
                ) : (
                    <Btn color="bronze" onClick={(e) => { e.stopPropagation(); }}>
                      <a href="https://ic-portal.burnbattle.io/">
                        <div className="flex gap-2 items-center pb-1">
                          <span className="relative top-0.5">PORTAL</span>
                        </div>
                      </a>
                    </Btn>
                )}
                {<ConnectWalletDropdown
                    show={showDropdown}
                    setShowDropdown={setShowDropdown}
                    connectPlugWallet={connectPlugWallet}
                    connectStoicWallet={connectStoicWallet}
                ></ConnectWalletDropdown>}
              </td>
            </tr>
          </table>
        </div>
      ) : (
        <div className="text-white text-xs font-normal flex flex-col items-center justify-start">
          {header && (
            <p onClick={() => setUserAddress("")} className="pb-1">{`${userAddress.slice(
              0,
              8
            )}...${userAddress.slice(-6)}`}</p>
          )}
          {/* <CWalletbutton
            onClick={() => {
              setShowPopup(true);
            }}
            text="Mint NFT"
            spcng="px-9"
            icon={false}
            type="filled"
          /> */}
        </div>
      )}
    </div>
  );
}

export default ConnectWallet;

import React, {useEffect, useState} from "react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Header from "./components/Header/Header";
import BattleAndBonuses from "./components/BattleAndBonuses/BattleAndBonuses";
import GameDescr from "./components/GameDescr/GameDescr";
import HeroScreen from "./components/HeroScreen/HeroScreen";
import WillYou from "./components/WillYou/WillYou";
import SimpleFun from "./components/SimpleFun/SimpleFun";
import RemainingPlayers from "./components/RemainingPlayers/RemainingPlayers";
import Roadmap from "./components/Roadmap/Roadmap";
import FAQ from "./components/FAQ/FAQ";
import Footer from "./components/Footer/Footer";
import useWindowSize from "./components/helpers/useWindowSize";
import axios from "axios";
import globals_functions from "./globals";
import Btn from "./components/Btn/Btn";
import ProgressBar from "./components/ProgressBar/progress-bar";
const globals = globals_functions();

function App() {
    const [showMenu, setShowMenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [modal_loader, setModal_loader] = useState({ state: false, text: "" });
    //const [modal_alert, setModal_alert] = useState({ state: false, text: "", icon: "soon.png" });

    const [width, setWidth] = useState(0);
    const currentWidth = useWindowSize();
    const handleScroll = (e) => {
        if (e.target.scrollingElement.scrollTop < 10) setScrolled(false);
        if (e.target.scrollingElement.scrollTop >= 10) setScrolled(true);
    };
    const handleScreenWidth = (e) => {
        setWidth(window.innerWidth);
    };

    document.addEventListener('show_modal_loader', function({ detail }) {
        //console.dir(detail);
        setModal_loader(detail);
    });

    /**
     * Set global API URL
     **/
    const global_params = async () => {
        let host = window.location.hostname
        if (host == 'localhost') {
            window.api_url = 'http://127.0.0.1:8000/';
        } else {
            window.api_url = 'https://ic-api.burnbattle.io/';
        }
    };

    window.canisters = [];
    window.canisters['burn_battle'] = "latb7-kqaaa-aaaai-qnpba-cai";
    window.canisters['burn_tokens'] = "xkmbr-yaaaa-aaaai-qocpa-cai"; // (fungible)

    window.actors = [];
    window.actors['battle'] = {};
    window.actors['burn'] = {};

    /*
   * ================= Candid: IDL  =====================
   */
    const get_IDL_Factory = () => {

        let idlFactory = [];

        //----------- Burn Battle main ------------
        idlFactory['burn_battle'] = ({IDL}) => {
            const TokenIndex = IDL.Nat32;
            const AccountIdentifier_3 = IDL.Text;
            const Metadata = IDL.Variant({
                'fungible': IDL.Record({
                    'decimals': IDL.Nat8,
                    'metadata': IDL.Opt(IDL.Vec(IDL.Nat8)),
                    'name': IDL.Text,
                    'symbol': IDL.Text,
                }),
                'nonfungible': IDL.Record({'metadata': IDL.Opt(IDL.Vec(IDL.Nat8))}),
            });
            const UserTokens = IDL.Variant({
                'ok': IDL.Vec(IDL.Nat32)
            });
            const ListRequest = IDL.Record({
                'token': IDL.Nat32,
                'price': IDL.Nat64
            });
            const Time = IDL.Int;
            return IDL.Service({
                'getRegistry': IDL.Func(
                    [],
                    [IDL.Vec(IDL.Tuple(TokenIndex, AccountIdentifier_3))],
                    [],
                ),
                'getTokens': IDL.Func(
                    [],
                    [IDL.Vec(IDL.Tuple(TokenIndex, Metadata))],
                    []
                ),
                'ledgerFromPrincipal': IDL.Func(
                    [IDL.Principal],
                    [IDL.Text],
                    [],
                ),
                'greet': IDL.Func([IDL.Text], [IDL.Text], []),
                'user_tokens': IDL.Func(
                    [IDL.Text],
                    [IDL.Vec(IDL.Tuple(IDL.Nat32, IDL.Text))],
                    [],
                ),
                'tokens': IDL.Func(
                    [IDL.Text],
                    [UserTokens],
                    [],
                ),
                'metadata': IDL.Func(
                    [IDL.Text],
                    [IDL.Variant({'ok': Metadata })],
                    []
                ),
                'buy_mint_token': IDL.Func(
                    [IDL.Text, IDL.Text],
                    [IDL.Text],
                    [],
                ),
                'prepare_order': IDL.Func(
                    [IDL.Text, IDL.Nat, IDL.Nat32],
                    [IDL.Text],
                    [],
                ),
                'check_order': IDL.Func(
                    [IDL.Text, IDL.Text, IDL.Text, IDL.Nat64],
                    [IDL.Text],
                    [],
                ),
            });
        };


        //----------- Burn fungible Tokens ------------
        idlFactory['burn_tokens'] = ({IDL}) => {

            const Transaction = IDL.Record({
                'to': IDL.Text,
                'from': IDL.Text,
                'amount': IDL.Nat,
                'time': IDL.Int,
            });

            return IDL.Service({
                'buy_nft_for_tokens': IDL.Func(
                    [IDL.Nat, IDL.Nat, IDL.Text, IDL.Nat],
                    [IDL.Text],
                    [],
                ),
                'prepare_order': IDL.Func(
                    [IDL.Text, IDL.Nat],
                    [IDL.Text],
                    [],
                ),
                'check_order': IDL.Func(
                    [IDL.Text, IDL.Text, IDL.Nat64],
                    [IDL.Text],
                    [],
                ),
                'user_transactions': IDL.Func(
                    [IDL.Text],
                    [IDL.Vec( Transaction )],
                    [],
                ),
            });
        };
        return window.idlFactory = idlFactory;
    };
    get_IDL_Factory();

    /**
     * Load season timer data from API
     **/
    const load_season_data = async () => {
        let result = await axios.post(window.api_url + 'api/portal-season-summary');
        //console.dir(result.data);
        window.season = result.data;
        //window.season.status = 'minting';
        window.dispatchEvent(new Event('season_data'));
    };

    /**
     * Load all species from local
     */
    const load_species_list = async ( params ) => {
        let characters_list = [
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Thrower",
                "preview": "/assets/types/1.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Sniper",
                "preview": "/assets/types/2.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Spearman",
                "preview": "/assets/types/3.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Destroyer",
                "preview": "/assets/types/4.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Archer",
                "preview": "/assets/types/5.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Punisher",
                "preview": "/assets/types/6.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Firestarter",
                "preview": "/assets/types/7.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Knight",
                "preview": "/assets/types/8.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Crusher",
                "preview": "/assets/types/9.png",
            },
            {
                "token_index": 0, "status" : 1,
                "specie_label": "Battle-axe",
                "preview": "/assets/types/10.png",
            },



        ];

        window.user_tokens = characters_list;
        let slides_total = Math.ceil( characters_list.length / 10 );
        //console.log( 'slides_total: ' + slides_total );

        let home_slides = [];
        for( let i = 0; i < slides_total; i++ ){
            home_slides.push( i );
        }
        window.home_slides = home_slides;
        document.dispatchEvent(new CustomEvent('update_slides', {detail: {}}));
    }

    /**
     * Load all NFT assets of this user
     */
    const load_characters = async ( params ) => {
        if( window.client_ledger ){
            let params = {  wallet: window.client_ledger ?? '' };
            globals.api_query('portal-characters', 'POST', params, (data) => {
                //console.dir( data);
                data.sort((a, b) => (a.new_banner < b.new_banner) ? 1 : -1);
                window.user_tokens = data;
                let slides_total = Math.ceil( data.length / 10 );
                //console.log( 'slides_total: ' + slides_total );

                let home_slides = [];
                for( let i = 0; i < slides_total; i++ ){
                    home_slides.push( i );
                }
                window.home_slides = home_slides;
                document.dispatchEvent(new CustomEvent('update_slides', {detail: {}}));
            }); // end - api query

            // -------------------------------------
            // load user's tokens from the connected canister
            window.actors['burn_battle'].user_tokens( window.client_ledger ).then( tokens => {
                //console.dir( tokens );
                globals.hide_Preloader();
                let token_indexes = [];
                for( let t of tokens){ token_indexes.push( t[0] ); }
                // load list of tokens from server by list of indexes
                let params = { tokens: token_indexes };
                globals.api_query('dfx-user-tokens', 'POST', params, (data) => {
                    //console.dir( data );
                    data.sort((a, b) => (a.new_banner < b.new_banner) ? 1 : -1);
                    window.user_tokens = data;
                    localStorage.setItem('tokens', JSON.stringify( data ) );
                }); // end - api query
            });// end - aсtor query

        }
    };

    //


    /**
     * Load all alive NFT for season
     */
    const load_alive_total = async () => {
        globals.api_query('season-alive-characters', 'GET', {}, (data) => {
            //console.dir( data.characters );
            window.total_alive = data.characters;
            document.dispatchEvent(new CustomEvent('total_alive', {detail: {}}));
            //timeout 2 second
            /*setTimeout( () => {
                document.dispatchEvent(new CustomEvent('total_alive', {detail: {}}));
            }, 2000);*/

        }); // end - api query
    }

    /**
     * Reconnect wallet from local data
     */
    const reconnect_wallet = async () => {

        let ic_params = localStorage.getItem('ic_params');
        //console.log('load_local_ic_params', ic_params);
        if( ic_params ) {
            let params = JSON.parse(ic_params);
            window.client_ledger = params.client_ledger;
            window.stoic_identity = params.stoic_identity;
            window.wallet_type = params.wallet_type;
            window.principal_key = params.principal_key;
            window.client_principal = params.client_principal;

            if( window.client_ledger ) {
                window.logged_user = true;
                let client_ledger = window.client_ledger;
                document.dispatchEvent(new CustomEvent('client_ledger', {detail: {client_ledger}}));
            }
        }
    }

    /**
     * List all listeners
     */
    function event_Listeners() {
        document.addEventListener('refresh_tokens', function({ detail }) {
            //console.log('refresh tokens')
            //load_characters( );
        });
    }

    useEffect(() => {
        global_params();
        load_season_data();
        //load_characters();
        load_species_list();
        event_Listeners();
        load_alive_total();
        reconnect_wallet();

       /* let timer_season =  setInterval(async () => {
            load_season_data();
        }, 25000);*/

        window.addEventListener("scroll", handleScroll);
        window.addEventListener("resize", handleScreenWidth);
        setWidth(window.innerWidth);
        return () => {
            window.addEventListener("resize", handleScreenWidth);
            window.removeEventListener("scroll", handleScroll);
        };
    });
    //console.log("currentWidth", currentWidth);
    return (
        <div>
            <Header scrolled={scrolled} showMenu={showMenu} setShowMenu={setShowMenu}/>
            <HeroScreen/>
            <GameDescr/>
            <WillYou/>
            <BattleAndBonuses/>
            <SimpleFun/>
            <RemainingPlayers/>
           {/* <Roadmap/>*/}
            <div className="gra bg-gradient-to-b from-brown-100 to-brown-50">
                <FAQ/>
                <div className="wrap h-0.5 bg-brown-500/10"></div>
                <Footer/>
            </div>

            { ( modal_loader.state ) && (
                <div className="burn_modal_overlay">
                    <div className="burn_modal_loader" style={{"maxWidth" : "450px", "width" : "90vw"}}>
                        <div className="full-width block text-center py-2">
                            <img src="/assets/logo.svg" className="m-auto" style={{"width" : "120px"}}/>
                        </div>
                        <div className="full-width block text-center py-4 px-10 modal_title">
                            { modal_loader.text }
                        </div>
                        <div className="flex flex-col items-center justify-start py-2 px-16">
                            <ProgressBar/>
                        </div>
                    </div>
                </div>
            )}

        </div>
    );
}

export default App;

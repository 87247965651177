import React, {useEffect, useState} from "react";
import Btn from "../Btn/Btn";
import Timer from "../Timer/Timer";
import HeroBG from "./HeroBG";
import styles from "../../styles/hero-screen.module.scss";
import SplashCarousel from "./Slider";
import globals_functions from "../../globals";
import Char from "../GameDescr/Char";
//import extjs from "../../ic/extjs";

export default function HeroScreen() {

    const globals = globals_functions();
    let [season, setSeason] = useState({ season_title: '', total_reward: 0, total_characters: 0 });
    let [show_mint_modal, setShow_mint_modal] = useState(false);
    let [wallet, setWallet] = useState( '');
    let [alive_modal, setAlive_modal] = useState(false);
    let [alive_characters, setAlive_characters] = useState([]);

    window.addEventListener('season_data', function (e) {
        setSeason(window.season);
    });
    document.addEventListener('client_ledger', function({ detail }) {
        setWallet( window.client_ledger ?? '');
    });

    document.addEventListener('total_alive', function({ detail }) {
        if( !window.user_tokens || 1 == 1  ){
            //console.log( "total_alive_popup" )
            for( let t of window.total_alive) { t.popup = 'yes'; }
            setAlive_characters(  window.total_alive );
        }
    });


 /*   // set Timeout 3 seconds
    let timer2 = setTimeout( () => {
        setAlive_characters(  window.total_alive );
        console.dir(window.total_alive)
    }, 3000);
*/
    /**
     * Buy and mint a new warrior
     */
    function buy_new_Character( count ) {
        setShow_mint_modal(false);
        globals.modalAlert('Coming soon', 'We are working on developing this functionality', 'success');
    }

    return (
    <div className={`${styles.root} overflow-hidden`}>
      <div className="absolute left-0 bottom-0 w-full h-full">
        {/* <img className={styles.bg} src="/assets/hero-screen/main-bg.svg" alt="Desert" /> */}
        <HeroBG className={styles.bg} />
        <div className="absolute w-full h-20 z-0 bg-brown-100 left-0 bottom-20 xl:hidden" />
      </div>
      <div className="wrap">
        <div className={`${styles.motto} stroke`}>
            <span> Will you be the <br className="hidden lg:block" /> last one standing? </span>
        </div>
        <p className={styles.subheading}>
            A multi-chain last man standing NFT <br className="lg:hidden" /> battle game - you lose - your NFT gets burnt.
            {/*An NFT battle game - you lose <br className="lg:hidden" /> - your NFT gets burnt.*/}
        </p>
        { (season.status == 'minting' || season.status == 'preparation') && (
        <div className="flex flex-col items-center gap-6 lg:flex-row justify-center">
           <div className="uppercase text-brown-400 text-2xl leading-none relative font-black text-center ">
              {season.season_title} <br/>
              { season.status == 'minting' ? 'STARTS IN' : '' }
              { season.status == 'preparation' ? 'Minting in' : ''}
              { season.status == 'active' ? 'is Live!' : ''}
          </div>
          <Timer />

          <div className="flex gap-3 lg:gap-6 items-center">
          { (season.status == 'minting')  && (
            <div className="relative ">
                <Btn color={"bronze"} style={{ padding: "6px" }} onClick={()=>{ setAlive_modal(true ) }}>
                <div className="flex flex-col items-center leading-none">
                  <div className="font-black text-2xl ">{season.total_characters}</div>
                  <div className="italic text-[10px]  capitalize flex items-center gap-1">
                    Total warriors{" "}
                    <img className="relative -top-px" src="/assets/people-icon.svg" alt="people" />
                  </div>
                </div>
              </Btn>
            </div>
            )}
           { (season.status == 'minting')  && (
            <div className="relative">
              <Btn color={"gray"} style={{ padding: "6px" }}>
                <div className="flex flex-col items-center leading-none">
                  <div className="font-black text-2xl ">{season.total_reward} ICP</div>
                  <div className="italic text-[10px]  capitalize flex items-center gap-1">
                    Total prizes in{" "}
                    <img
                      className="relative -top-px"
                      src="/assets/icp-gray.svg"
                      alt="ICP Blockchain Token"
                    />
                  </div>
                </div>
              </Btn>
            </div>
           )}
          </div>

          { season.status == 'minting' && (
              <a href="https://ic-portal.burnbattle.io/#/login?mint" target="_blank">
                  <Btn color={"green"} style={{ padding: "16px" }}>
                      <div className="flex flex-col items-center leading-none">
                          <div className="font-black text-2xl "> Mint </div>
                      </div>
                  </Btn>
              </a>
          )}
        </div>
        )}

       { (season.status == 'active' ) && (
           <div className="flex flex-col items-center gap-6 lg:flex-row justify-center">
                <div className="uppercase text-brown-400 text-2xl leading-none relative font-black text-center ">
                  {/* {season.season_title} <br/>*/} Season is Live!
                </div>
                <div className="relative ">
                    <Btn color={"gray"} style={{ padding: "6px" }}>
                        <div className="flex flex-col items-center leading-none px-5">
                            <div className="font-black text-2xl ">{season.rounds.completed} / {season.rounds.total}</div>
                            <div className="italic text-[10px]  capitalize flex items-center gap-1">
                                Round Number
                            </div>
                        </div>
                    </Btn>
                </div>

                <div className="relative ">
                    <Btn color={"bronze"} style={{ padding: "6px" }} onClick={()=>{ setAlive_modal(true ) }}>
                        <div className="flex flex-col items-center leading-none px-5">
                            <div className="font-black text-2xl ">{season.alive} / {season.total_characters}</div>
                            <div className="italic text-[10px]  capitalize flex items-center gap-1">
                                Remaining warriors{" "}
                                <img className="relative -top-px" src="/assets/people-icon.svg" alt="people" />
                            </div>
                        </div>
                    </Btn>
                </div>

                <div className="relative">
                    <Btn color={"gray"} style={{ padding: "6px" }}>
                        <div className="flex flex-col items-center leading-none px-5">
                            <div className="font-black text-2xl ">{season.total_reward} ICP</div>
                            <div className="italic text-[10px]  capitalize flex items-center gap-1">
                                Total prizes in{" "}
                                <img  className="relative -top-px"  src="/assets/icp-gray.svg"   />
                            </div>
                        </div>
                    </Btn>
                </div>

                <div className="relative ">
                    <Btn color={"bronze"} style={{ padding: "6px" }}>
                        <div className="flex flex-col items-center leading-none px-5">
                            <div className="font-black text-2xl "> {season.grand_prize} ICP</div>
                            <div className="italic text-[10px]  capitalize flex items-center gap-1">
                                Grand Prize {" "}
                                <img  className="relative -top-px"  src="/assets/icp-gray.svg"   />
                            </div>
                        </div>
                    </Btn>
                </div>
        </div>
       )}
      </div>
      <div className="relative z-10  w-full -translate-y-[5%]">
        <SplashCarousel />
      </div>

      { (show_mint_modal && wallet != '') && (
      <div className="burn_modal_overlay">
          <div className="burn_container burn_modal" style={{"maxWidth" : "650px", "width" : "90vw"}}>
              <div className="grid grid-cols-2">
                  <div className="">
                      <div className=" text-yellow-200"> Please Select Bundle </div>
                  </div>
                  <div className="text-right border-emerald-50">
                     <img src="/assets/x-mark.svg" onClick={()=>{ setShow_mint_modal(false) }}
                          className="close_btn float-right" />
                  </div>
              </div>
              <div className="full-width block text-center py-2">
                  <img src="/assets/logo.svg" className="m-auto" style={{"width" : "120px"}}/>
              </div>
              <div className="full-width block text-center py-4 modal_title">
                  WELCOME TO THE BURN BATTLE SALE
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-4 lg:gap-6 gap-4">
                  <div class="col-3 q-px-sm cursor-pointer" onClick={()=>{ buy_new_Character(1) }}>
                    <img src="/assets/mint/buy_bundle_1.png" class="full-width"/>
                  </div>
                  <div class="col-3 q-px-sm cursor-pointer" onClick={()=>{ buy_new_Character(5) }}>
                      <img src="/assets/mint/buy_bundle_2.png" class="full-width"/>
                  </div>
                  <div class="col-3 q-px-sm cursor-pointer" onClick={()=>{ buy_new_Character(10) }}>
                    <img src="/assets/mint/buy_bundle_3.png" class="full-width"/>
                  </div>
                  <div class="col-3 q-px-sm cursor-pointer" onClick={()=>{ buy_new_Character(20) }}>
                    <img src="/assets/mint/buy_bundle_4.png" class="full-width"/>
                  </div>
              </div>
              <div className="full-width block text-center py-2 text-white text-xs pt-4">
                  All transactions are secured via Stoic or Plug wallets. There are no refunds or returns, once a transaction is made it can not be reversed.By click in one of the buttons above you show acceptance Terms of Service.
              </div>
              <div className="flex flex-col items-center justify-start py-2">
                  <Btn color="grey" onClick={()=>{ setShow_mint_modal(false) }}>
                      <div className="text-white text-xs font-normal flex flex-col items-center justify-start uppercase">
                      Close window
                      </div>
                  </Btn>
              </div>
          </div>
      </div>
      )}
     { (show_mint_modal && wallet == '') && (
          <div className="burn_modal_overlay">
            <div className="burn_container burn_modal" style={{"maxWidth" : "650px", "width" : "90vw"}}>
                <div className="grid grid-cols-2">
                    <div className="">
                        <div className=" text-yellow-200"> Purchase Process Error </div>
                    </div>
                    <div className="text-right border-emerald-50">
                        <img src="/assets/x-mark.svg" onClick={()=>{ setShow_mint_modal(false) }}
                             className="close_btn float-right" />
                    </div>
                </div>
                <div className="full-width block text-center py-2">
                    <img src="/assets/modal/modal_error.svg" className="m-auto" style={{"width" : "120px"}}/>
                </div>
                <div className="full-width block text-center py-4 modal_title">
                    You must connect your wallet <br/> to buy a new warrior
                </div>
                <div className="flex flex-col items-center justify-start py-2">
                    <Btn color="grey" onClick={()=>{ setShow_mint_modal(false) }}>
                        <div className="text-white text-xs font-normal flex flex-col items-center justify-start uppercase">
                            Got it
                        </div>
                    </Btn>
                </div>
            </div>
          </div>
      )}

        { (alive_modal) && (
            <div className="burn_modal_overlay">
                <div className="burn_container burn_modal" style={{"maxWidth" : "650px", "width" : "90vw"}}>
                    <div className="grid grid-cols-2">
                        <div className="">
                            <div className="modal_title text-black"> Total Warriors </div>
                        </div>
                        <div className="text-right border-emerald-50">
                            <img src="/assets/x-mark.svg" onClick={()=>{ setAlive_modal(false ) }}
                                 className="close_btn float-right" />
                        </div>
                    </div>
                    <br/>
                    <div className="modal_scrolled">
                        <div className="grid grid-cols-4 lg:grid-cols-4 lg:gap-6 gap-4">
                            {alive_characters.map((char, i) => (
                                <Char key={`${char.specie_label}_${i}`} char={char} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        )}

    </div>
  );
}

import React from "react";
import DropdownLayout from "./DropdownLayout";
import Btn from "../Btn/Btn";
export default function NetworkDropdown({
  show, setShowDropdownNetwork, selectNetwork_ICP, selectNetwork_SOL, selectNetwork_ETH, selectNetwork_APT, selectNetwork_SUI, selectNetwork_BTC
}) {
  const buttons = [
    { name: "icp", imgSrc: "/assets/icp-gray.svg", onClick: selectNetwork_ICP },
    { name: "apt", imgSrc: "/assets/aptos_logo.svg", onClick: selectNetwork_APT },
    { name: "btc", imgSrc: "/assets/btc_logo.png", onClick: selectNetwork_BTC },
/*    { name: "sol", imgSrc: "/assets/solana_logo.svg", onClick: selectNetwork_SOL },
    { name: "eth", imgSrc: "/assets/ethereum_logo.svg", onClick: selectNetwork_ETH },
    { name: "sui", imgSrc: "/assets/sui_logo.png", onClick: selectNetwork_SUI },*/
  ];
  return (
    <DropdownLayout show={show} setShowDropdown={setShowDropdownNetwork}>
      <div className="flex flex-col gap-5 p-1">
        {buttons.map((b, i) => (
          <div key={b.name} onClick={b.onClick} className="flex gap-3 items-center relative">
            <Btn color="bronzeSmall" containerClassName="rounded">
              SELECT
            </Btn>
            <span className={ b.name != 'icp' ? 'text-gray-400 uppercase' : 'text-white uppercase' }>{b.name}</span>
            <img className="icon-logo" src={b.imgSrc} style={{"width":"22px"}}/>
            {i < buttons.length - 1 && (
              <div className="absolute h-px w-full bg-black bottom-0 left-0 translate-y-3 " />
            )}
          </div>
        ))}
      </div>
    </DropdownLayout>
  );
}

import React, { useState } from "react";
import FaqItem from "./FaqItem";

const faqs = [
  {
    id: "what",
    iconSrc: "/assets/bonuses/burn.svg",
    qstn: "HOW MANY NFT CHARCOAL WARRIORS CAN I BUY?",
    answr: `You can buy as many charcoal warriors as you want per wallet - the more warriors you have means the more opportunities you have to progress to become a champion and win the grand prize.`,
  },
  {
    id: "how-will",
    iconSrc: "/assets/bonuses/sword.svg",
    qstn: "HOW OFTEN DOES THE TOURNAMENT GET RUN?",
    answr: `The tournament is run weekly - across different blockchains - so you can choose your favourite blockchain/crypto currency to play with and to win.`,
  },
  {
    id: "what-are",
    iconSrc: "/assets/bonuses/timer.svg",
    qstn: "WHAT BLOCKCHAINS IS IT RUN ON?",
    answr: `As of now - it is only launched on DFINITY ICP as a beta trial - but we are expanding to ETHEREUM, SOLANA, APTOS and CARDANO very soon.`,
  },
  {
    id: "what-is",
    iconSrc: "/assets/bonuses/gift.svg",
    qstn: "WHAT CRYPTO CURRENCY DO I NEED TO PURCHASE?",
    answr: `You can buy NFT warriors using the relevant blockchain native currency - please see the top right corner for which blockchain or wallet you will need to be able to play.`,
  },
  {
    id: "who-are",
    iconSrc: "/assets/bonuses/speedup.svg",
    qstn: "DO YOU HAVE FUTURE PLANS FOR THE PROJECT TO IMPROVE THE GAME?",
    answr: `Yes, as the game progresses in popularity and more users play - we will continue to use 25% of all royalties of the game - to improve, market and make the whole project better.`,
  },
];
export default function FAQ() {
  const [currentFaq, setCurrentFaq] = useState("");
  return (
    <div id="faq" className="wrap">
      <div className=" relative py-5">
        <p className="heading text-center">FREQUENTLY ASKED QUESTIONS</p>
        <p className="paragraph text-center mt-2 mb-5">
          Some of the questions that you may have about Burn Battle are below
        </p>
        <div className="grid grid-cols-1  gap-4 lg:gap-4 w-full mb-14 lg:mb-20">
          {faqs.map((f, i) => (
            <FaqItem
              key={f.id}
              id={f.id}
              currentFaq={currentFaq}
              setCurrentFaq={setCurrentFaq}
              delay={i}
              {...f}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
